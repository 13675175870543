<template>
    <a
    :href="item.activationLink"
    target="_blank"
    class="card"
    >
    <!-- <div class="deal-card-wrapper"> -->
        <div class="card-img-wrapper">
            <img
            class="card-img" 
            :src="item.image" 
            :alt="item.merchant.name"
            />
        </div>
        <h4 class="card-description">{{ shortText(item.name, 50) }}</h4>
        <div class="card-expired">
            valid until {{ expireDate(item.endDate) }}
        </div>
    <!-- </div> -->
    </a>
</template>

<script>
import { shortText, expireDate } from '@/assets/utils.js'

export default {
    name: 'DealCard',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        shortText,
        expireDate
    },
}
</script>
<style lang="scss" scoped>
.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    width: 280px;
    height: 472px;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px -4px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    &-img-wrapper {
        width: 280px;
        height: 320px;
    }

    &-img{
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        max-width: 280px;
        max-height: 320px;
    }

    &-description {
        height: 84px;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        margin: 0px 32px 0px 32px;
        color: #303944;
    }

    &-expired {
        margin: 0px 32px 32px 32px;
        font-weight: 400;
        font-size: 12px;
        color: #7B8087;
    }
}
</style>
