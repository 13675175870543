<template>
  <main class="app">
    <router-view />
    <!-- <app-header />
    <main-section />
    <features-section />
    <caa-feature-section />
    <offers-section />
    <advantages-section />
    <last-chance-section />
    <app-footer /> -->
  </main>
</template>

<script>
// import AppHeader from '@/components/AppHeader'
// import MainSection from '@/components/MainSection'
// import FeaturesSection from '@/components/FeaturesSection'
// import CaaFeatureSection from '@/components/CaaFeatureSection'
// import OffersSection from '@/components/OffersSection'
// import AdvantagesSection from '@/components/AdvantagesSection' 
// import LastChanceSection from '@/components/LastChanceSection' 
// import AppFooter from '@/components/AppFooter'



export default {
  name: 'App',
  // mounted() {
  //   let img = new Image();
  //   img.src = './assets/images/caa-feature-3.png'
  // },
  // components: {
  //   AppHeader,
  //   MainSection,
  //   FeaturesSection,
  //   CaaFeatureSection,
  //   OffersSection,
  //   AdvantagesSection,
  //   LastChanceSection,
  //   AppFooter
  // } 
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&display=swap');

html, body {
  margin: 0px;
  padding: 0px;
  background: #FAFAFA;
  font-family: 'Inter';
  scroll-behavior: smooth;
  min-height: 100vh;
}
.app {
  display: flex;
  flex-direction: column;
}
</style>
