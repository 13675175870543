<template>
    <div
    @click="closeModal" 
    class="main"
    >
        <!-- Main Section -->
        <section class="main-section">

            <!-- QR Modal Window -->
            <modal
            v-if="showModal"
            @closeModal="closeModal"
            />

            <div class="main-text-wrapper">
                <h1 class="main-title">Most <span class="main-title-blue">convenient</span> way to save on buying online </h1>
                <div class="main-links">
                    <a
                    target="_blank"
                    :href="links.ios"
                    >
                        <img 
                        class="main-appstore-img"
                        src="../assets/images/main-app-store.png" 
                        alt="AppStore"
                        />
                    </a>
                    <button 
                    @click.stop="openModal"
                    class="main-qr-btn"
                    >
                        <img 
                        src="../assets/icons/qr-icon.svg" 
                        alt="QR"
                        >
                        <span class="main-qr-btn-text">Open QR Code</span> 
                    </button>
                </div>
            </div>
            <div class="main-img-wrapper">
                <img class="main-img" src="../assets/images/main-phone.png" alt="iPhone">
            </div>
        </section>
    </div>
</template>

<script>
import Modal from '@/components/Modal'
import { APP_LINKS } from '@/assets/constants'


export default {
    name: 'MainSection',
    components: {
        Modal,
    },
    data() {
        return {
            showModal: false
        }
    },
    computed: {
        links() {
            return APP_LINKS;
        }
    },
    methods: {
        openModal() {
            this.showModal = true
        },
        closeModal () {
            this.showModal = false
        }
    },
}
</script>

<style lang="scss" scoped>
.main {
    // max-height: 900px;

    &-section {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 132px;
    }

    &-text-wrapper {
        max-width: 688px;
    }

    &-title {
        font-weight: 900;
        font-size: 72px;
        color: #1A233F;

        &-blue {
            color: #2F80ED;
        }
    }

    &-links {
        display: flex;
    }

    &-qr-btn {
        display: flex;
        align-items: center;
        background: #FFFFFF;
        padding: 16px;
        box-shadow: 0px 8px 24px -4px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        border: none;
        margin-left: 12px;
        cursor: pointer;

        &-text {
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #5F6266;
            margin-left: 16px;
        }
    }

    &-img {
        max-width: 100%;
    }

    &-appstore-img {
        max-width: 100%;
    }

    @media (max-width: 992px) {

        &-section {
            flex-direction: column-reverse;
            padding: 0 20px;
            margin-top: 0px;
            margin-left: auto;
            margin-right: auto;
        }

        &-title {
            font-weight: 700;
            font-size: 42px;
            text-align: center;
            width: 344px;
        }

        &-links {
            flex-direction: column;
            align-items: center;
            margin-top: 60px;
        }

        &-qr-btn {
            margin-left: 0px;
            margin-top: 12px;
            width: 180px;

            &-text {
                font-size: 15px;
                font-weight: 600;
            }
        }
        
        &-img {
            margin-top: 40px;
            margin-bottom: 20px;
            width: 250px;
            height: auto;
        }
    }

    @media (max-width: 1200px) {
        padding: 0 20px;
    }
}
</style>