<template>
    <section class="advantages-section">
        <advantage-card
        class="advantages-section-cards"
        v-for="(advantage, i) in agvantages" 
        :key="i"
        :title="advantage.title"
        :icon="advantage.image"
        />
    </section>
</template>

<script>
import AdvantageCard from '@/components/AdvantageCard'
import { ADVANTAGES } from '@/assets/constants'

export default {
    name: 'AdvantagesSection',
    components: {
        AdvantageCard
    },
    computed: {
        agvantages() {
            return ADVANTAGES
        }
    }
}
</script>

<style lang="scss" scoped>
.advantages-section {
    display: flex;
    justify-content: center;
    // flex-wrap: 2;
    // align-content: center;
    margin-top: 256px;
    flex-wrap: wrap;

    &-cards {
        flex: 1 0 30%; 
        margin-bottom: 16px;
        
    }
    @media (max-width: 1200px) {

        &-cards {
         margin-right: 16px;
            
        }
        
    }

    @media (max-width: 992px) {
        margin-top: 150px;
        

        &-cards {
            flex: 1 0 50%; 
            flex-direction: column;
            // margin-right: 16px;
            
        }
        
    }
    
    @media (max-width: 767px) {
        margin-top: 150px;
        padding: 0 20px;

        &-cards {
            flex: 1 0 50%; 
            margin-left: 0;
            margin-right: 0;
            flex-direction: column;
            
        }
        
    }
    
}
</style>