import moment from 'moment';


const shortText = (text, amount) =>{
    text = text.trim();

    return text.length > amount 
    ? text.substr(0, amount) + '...' : text
    // let words = text.split(" ");

    // return words.length > amount
    // ? words.slice(0, amount).join(" ") + "..."
    // : text;
}

const expireDate = (str) => {
    try {
      return moment(str).format('L');
    } catch (e) {
      return str;
    }
  }

const copyText = (code) => {
    let copyText = code
    // console.log(offer.couponCode)
    let textArea = document.createElement("textarea")
    textArea.innerHTML = copyText;
    document.body.appendChild(textArea);
    textArea.select()
    document.execCommand("Copy")
    textArea.remove()
}

export {
    shortText,
    expireDate,
    copyText
}