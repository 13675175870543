<template>
    <header class="header">
        <div class="header-logo"></div>
        <a 
        class="header-link"
        target="_blank"
        :href="links.ios"
        >
        <div class="header-button">Get the app</div>
        </a>
    </header>
</template>

<script>
import { APP_LINKS } from '@/assets/constants'

export default {
    name: 'AppHeader',
    computed: {
        links() {
            return APP_LINKS;
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 60px 0 60px;

    &-logo {
        background-image: url("../assets/sharky-logo.svg");
        background-repeat: no-repeat;
        height: 32px;
        width: 128px;
    }

    &-link {
        text-decoration: none;
    }

    &-button {
        border: 1px solid #2F80ED;
        border-radius: 16px;
        color: #2F80ED;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding: 12px 24px;
        background-color: #fafafa;
        cursor: pointer;
    }
    @media (max-width: 767px) {
        padding: 20px;
    }
}
</style>