<template>
    <div class="controls">
        <div
        :class="{ hideControls: !showControls }"
        @click="$emit('controlClick')" 
        class="controls-wrapper"
        >
            <div
            class="controls-button"
            :style="{ backgroundImage: this.icon }"
            >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contols',
    props: {
        varient: {
            type: String,
            required: true
        },
        showControls: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            icon: `url(${require(`../assets/icons/controls-${this.varient}.svg`)})`
        }
    },
}
</script>

<style lang="scss" scoped>
.controls {
    display: flex;
    align-items: center;

    
    &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 18px;
        margin-right: 8px;
        margin-left: 8px;
        cursor: pointer;
    }

    &-button {
        width: 8px;
        height: 10px;
        background-repeat: no-repeat;

        
    }

    &:hover &-button {
        filter: invert(43%) sepia(75%) saturate(2836%) hue-rotate(199deg) brightness(96%) contrast(92%);
    } 

    @media (max-width: 767px) {

        &-wrapper {
            margin-right: 2px;
            margin-left: 2px;
        }
        
    }
    
}
.hideControls {
    visibility: hidden;
}
</style>