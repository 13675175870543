<template>
    <div>
        <app-header />
        <main-section />
        <features-section />
        <caa-feature-section />
        <offers-section />
        <advantages-section />
        <last-chance-section />
        <app-footer />
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import MainSection from '@/components/MainSection'
import FeaturesSection from '@/components/FeaturesSection'
import CaaFeatureSection from '@/components/CaaFeatureSection'
import OffersSection from '@/components/OffersSection'
import AdvantagesSection from '@/components/AdvantagesSection' 
import LastChanceSection from '@/components/LastChanceSection' 
import AppFooter from '@/components/AppFooter'
export default {
    name: 'HomePage',
    components: {
        AppHeader,
        MainSection,
        FeaturesSection,
        CaaFeatureSection,
        OffersSection,
        AdvantagesSection,
        LastChanceSection,
        AppFooter
    }
}
</script>