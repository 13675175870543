<template>
    <div class="coupon">
        <div 
        class="coupon-cover"
        :class="{'coupon-cover-opened': showCode}"
        >
        <span class="coupon-flap"></span>
            <span class="coupon-cover-text">Use coupon</span>
        </div>
        <div class="coupon-code">
            {{ code }} 
        </div>
    </div>
</template>

<script>
export default {
    name: 'CouponCode',
    props: {
        code: {
            type: String,
            required: true
        },
        showCode: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style lang="scss">
.coupon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 160px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 16px;
    position: relative;
    cursor: pointer;
    margin: 0px 0px 16px 32px;
    // overflow: hidden;

    &-cover {
    display: flex;
    // margin-left: 22px;
    align-items: center;
    border-radius: 16px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #2F80ED;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FAFAFA;
    clip-path: polygon(0 0, 80% 0, 90% 100%, 0% 100%);
    transition: clip-path 0.3s;

    &-text {
        margin-left: 24px;
    }

    &-opened {
        clip-path: polygon(0 0, 10% 0%, 20% 99%, 0% 100%);
        background: #3970B8;
    }
    }

    &-flap {
        position: absolute;
        right: 10px;
        clip-path: circle(50% at 50% 50%);
        width: 37px;
        height: 100%;
        background: #3970B8;
    }

    &-code {
        font-weight: 600;
        font-size: 12px;
        color: #303944;
        margin-right: 8px;
    }
}
</style>