import Vue from 'vue'
import Router from 'vue-router'
import PrivacyPolicy from '@/components/PrivacyPolicy'
import HomePage from '@/components/HomePage'
import PageNotFound from '@/components/PageNotFound'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
      { path: '/', component: HomePage },
      { path: '/privacy-policy', component: PrivacyPolicy },
      { path: '*', component: PageNotFound }
    ],
    scrollBehavior() {
        // always scroll to top
        return { 
            x: 0, y: 0,
            behavior: 'smooth',
        }
      },
})
