<template>
    <section class="last-chance">
        <div class="last-chance-wrapper">
            <div>
                <h2 class="last-chance-title">Stop 
                <span class="last-chance-title-red">wasting money</span>! Start 
                <span class="last-chance-title-blue">saving more</span> today!
            </h2>
            </div>
            
            <div>
                
            </div>
            <a
            class="last-chance-store-link"
            target="_blank"
            :href="links.ios"
            >
                <img 
                class="last-chance-wrapper-img"
                src="../assets/images/main-app-store.png" 
                alt="AppStore"
                />
            </a>
        </div>
        <div>
            <img
            class="last-chance-img"
            src="../assets/images/main-phone.png" 
            alt="iPhone"
            />
        </div>
    </section>
</template>

<script>
import { APP_LINKS } from '@/assets/constants'

export default {
    name: "LastChanceSection",
    computed: {
        links() {
            return APP_LINKS
        }
    }
}
</script>

<style lang="scss" scoped>
.last-chance {
    display: flex;
    justify-content: center;
    margin-top: 256px;

    &-wrapper {
        display: flex;
        flex-direction: column;
        align-self: center;
    }
    &-title {
        font-weight: 900;
        font-size: 48px;
        line-height: 125%;
        max-width: 688px;
        margin-right: 50px;
        margin-bottom: 72px;
        color: #1A233F;

        &-red {
            color: #EC3D0C;
        }

        &-blue {
            color: #2F80ED;
        }
    }

    &-img {
        max-width: 100%;
    }

    &-store-link {
        width: 180px;
        height: 60px;
    }

    @media (max-width: 1200px) {
        padding: 0 20px;
    }

    @media (max-width: 992px) {
        margin-top: 150px;
        padding: 0 20px;
        flex-direction: column-reverse;
        align-items: center;

        &-title {
            text-align: center;
            font-weight: 600;
            font-size: 38px;
            max-width: 400px;
            margin-right: 0;
        }

        &-img {
            width: 250px;
            height: auto;
        }

        &-store-link {
            align-self: center;
        }
    }
}
</style>