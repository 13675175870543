<template>
    <section class="offers-section">
        <offers-block
        v-if="this.deals.length"
        card-name="DealCard"
        title="Top"
        coloredPart="Deals"
        :items-per-page="this.itemsPerPage"
        :page-number="1"
        :items="this.deals"
        />

        <offers-block
        v-if="this.coupons.length"
        card-name="CouponCard"
        title="Top"
        coloredPart="Coupons"
        :itemsPerPage="this.itemsPerPage"
        :pageNumber="1"
        :items="this.coupons"
        />
    </section>
</template>

<script>
import OffersBlock from '@/components/OffersBlock'
import axios from 'axios'


export default {
    name: 'offersSection',
    components: {
        OffersBlock
    },
    data() {
        return {
            deals: [],
            coupons: [],
            itemsPerPage: 4
        }
    },
    created() {
        this.getOffers(),
        this.setItemsPerPage()
    },
    computed: {
        getScreenSize() {
            return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            
        }
    },
    methods: {
        async getOffers() {
            try {
                let deals = axios.get('https://sharky.website/api/offers/top?offerType=DEAL&pageSize=20&pageNumber=0')
                let coupons = axios.get('https://sharky.website/api/offers/top?offerType=COUPON&pageSize=20&pageNumber=0')
                
                const [dealsResponse, couponsResponse] = await Promise.all([
                        deals,
                        coupons
                    ])
                    this.deals = dealsResponse.data.data
                    this.coupons = couponsResponse.data.data
            } catch (error) {
                // console.log(error)
            }
        },
        setItemsPerPage() {
            let width = this.getScreenSize
    
            if (width < 576	) {
                this.itemsPerPage = 1
            }
            if (width >= 576 ) {
                this.itemsPerPage = 1
            }
            if (width >= 768 ) {
                this.itemsPerPage = 2
            }
            if (width >= 992) {
                this.itemsPerPage = 3
            }
            if (width >= 1200 ) {
                this.itemsPerPage = 4
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.offers-section {
    // overflow: hidden;
    margin-top: 256px;

    @media (max-width: 992px) {
        margin-top: 150px;
    }
}
</style>
