<template>
    <div class="offers-block">
        <h4 class="offers-block-title">{{ title }} <span class="offers-block-title-blue">{{ coloredPart }}</span></h4>
        
        <offers-slider
        :card-name="cardName"
        :items="items"
        :items-per-page="itemsPerPage"
        :page-number="pageNumber"
        />
    </div>
</template>

<script>
import OffersSlider from '@/components/OffersSlider'

export default {
    name: 'OffersBlock',
    components: {
        OffersSlider
    },
    props: {
        title: {
            type: String,
            required: true
        },
        coloredPart: {
            type: String,
            required: true
        },
        cardName: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        itemsPerPage: {
            type: Number,
            required: true
        },
        pageNumber: {
            type: Number,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.offers-block {
    // margin-bottom: 40px;
    margin-left: 100px;
    margin-right: 100px;

    &-title {
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        color: #1A233F;
        margin-left: 80px;

        &-blue {
            color: #2F80ED;
        }
    }

    @media (max-width: 1200px) {
        margin: 0;

        &-title {
            margin-left: 150px;
        }
    }

    @media (max-width: 992px) {
        margin: 0;
        // padding: 0 10px;

        &-title {
            margin-left: 50px;
        }
    }
}
</style>