<template>
    <div class="error-page">
        <div class="error-page-code">404</div>
        <div class="error-page-title">Page Not Found</div>
        <router-link class="error-page-link" to="/">Go Home</router-link>
    </div>
</template>

<script>
export default {
    name: 'PageNotFound'
}
</script>

<style lang="scss" scoped>
.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    &-code {
        font-size: 150px;
        color: #2F80ED;
        font-weight: bold;
    }

    &-title {
        font-size: 50px;
        text-transform: uppercase;
        margin-bottom: 24px;
    }

    &-link {
        text-decoration: none;
        background-color: #2F80ED;
        padding: 16px 40px;
        border-radius: 16px;
        color: #fafafa;
        font-weight: bold;
        text-transform: uppercase;
    }
}
</style>