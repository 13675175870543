<template>
    <div class="modal">
        <img 
        class="modal-img"
        src="../assets/images/app-qr.png" 
        alt="App QR"
        />
        <button @click="$emit('closeModal')" class="modal-close-btn">Close QR code</button>
    </div>
</template>

<script>
export default {
    name: "Modal"
}
</script>

<style lang="scss" scoped>
.modal {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 24px -4px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    background: #FFFFFF;
    position: absolute;
    top: 180px;
    padding: 20px;

    &-close-btn {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #FAFAFA;
        background: #2F80ED;
        border-radius: 16px;
        border: none;
        padding: 12px 24px;
        margin-top: 16px;
        cursor: pointer;
    }

    @media (max-width: 992px) {
        top: 500px;
    }
}
</style>
