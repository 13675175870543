<template>
    <div class="footer">
        <div class="footer-wrapper">
            <div class="footer-logo"></div>
            <span class="footer-address">Sharky Tech Inc. 1178 Broadway, 3rd floor, #1540 <br /> New York, NY 10001</span>
            <router-link 
            class="footer-privacy" 
            to="/privacy-policy"
            >
            Privacy Policy
            </router-link>
        </div>
        <div>
            <a 
            target="_blank"
            :href="links.ios"
            >
                <img
                class="footer-link"
                src="../assets/images/footer-app-store.png" 
                alt="App Store"
                />
            </a>
        </div>
        
    </div>
</template>

<script>
import { APP_LINKS } from '@/assets/constants'

export default {
    name: "AppFooter",
    computed: {
        links() {
            return APP_LINKS
        }
    }
}
</script>

<style lang="scss" scoped>
.footer {
    display: flex;
    justify-content: space-between;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // align-items: center;
    padding: 80px 60px 44px 60px;
    background: #303944;
    margin-top: 160px;
    
    &-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 380px;
    }

    &-logo {
        background-image: url("../assets/icons/footer-logo.svg");
        width: 256px;
        height: 64px;
        margin-bottom: 44px;
    }

    &-address {
        max-width: 380px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #878B90;
        
    }

    &-privacy {
        margin-top: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        color: #FFFFFF;
    }

    @media (max-width: 767px) {
        &-link {
            display: none;
        }
    }
}
</style>