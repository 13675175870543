const APP_LINKS = {
    ios: 'https://apps.apple.com/us/app/sharky-coupons-deals/id1663442050'
}

const FEATURES_SECTION_SLIDES = [
    {
        id: 1,
        imgName: 'features-slide-1.png',
        description: 'Not sure what you really want right now? Check out the deals feed with the most attractive discounts. There might be something you needed for a long time.'   
    },
    {
        id: 2,
        imgName: 'features-slide-2.png',
        description: 'There is always that one shop we prefer to the others. With Sharky, you can be sure you won’t miss any money-saving opportunities to purchase something really cool.'    
    },
    {
        id: 3,
        imgName: 'features-slide-3.png',
        description: 'No matter if it’s a new cafe or photography class you’re looking for, all you need is to choose the necessary category. An array of stores, courses and much more are waiting for you!'    
    },
    
]

const CAA_FEATURE_SECTION_SLIDES = [
    {
        id: 1,
        imgName: 'caa-feature-1.png',
        description: 'Let Sharky save your time and make the shopping experience more satisfying. The app automatically explores all available coupons for the store opened in Safari.'   
    },
    {
        id: 2,
        imgName: 'caa-feature-2.png',
        description: 'No need to get distracted by attempts to save coupons right in the middle of the shopping fun! Don’t worry, they will be waiting for you at the checkout.'    
    },
    {
        id: 3,
        imgName: 'caa-feature-3.png',
        description: 'Once the coupon is applied, you will see the original and discount prices. Our little helper’s job is done, but Sharky’s always happy to see you back.'    
    },
    
]

const SLIDER_DOTS_COLOR = {
    light: {
        backgroundColor: '#D8D8D8',
        activeColor: '#303944',
        descriptionColor: '#1A233F'
    },
    dark: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        activeColor: '#FFFFFF',
        descriptionColor: '#FAFAFA'
    }
}

const ADVANTAGES = [
    {
        title: 'Free application which makes your shopping effortless. Additionally, it saves your time and money.',
        image: 'advantage-1.svg'
    },
    {
        title: 'Extensive number of stores collected in the Sharky mobile app.',
        image: 'advantage-2.svg'
    },
    {
        title: 'Automatic coupon savings and application while shopping in Safari.',
        image: 'advantage-3.svg'
    },
    {
        title: 'Access to the best deals and discounts from various stores.',
        image: 'advantage-4.svg'
    },
]

    


export {
    APP_LINKS,
    FEATURES_SECTION_SLIDES,
    CAA_FEATURE_SECTION_SLIDES,
    SLIDER_DOTS_COLOR,
    ADVANTAGES
}