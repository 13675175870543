<template>
    <section class="features">
        <div class="features-title-wrapper">
            <h2 class="features-title">We sure about what user <span class="features-title-blue">really needs</span></h2>
        </div>
        <feature-card
        :features="features"
        :dotsColorSchema="dotsColor.light"
        />
    </section>
</template>

<script>
import FeatureCard from '@/components/FeatureCard'
import { FEATURES_SECTION_SLIDES, SLIDER_DOTS_COLOR } from '@/assets/constants'

export default {
    name: 'FeaturesSection',
    components: {
        FeatureCard
    },
    computed: {
        features() {
            return FEATURES_SECTION_SLIDES
        },
        dotsColor() {
            return SLIDER_DOTS_COLOR
        }
    }
}
</script>

<style lang="scss" scoped>
.features {
    display: flex;
    justify-content: center;
    margin-top: 300px;
    height: 620px;
    // width: 1000px;
    
    &-title {
        max-width: 400px;
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        margin-right: 40px;
        color: #1A233F;

        &-blue {
            color: #2F80ED;
        }
    }

    @media (max-width: 1200px) {
        padding: 0 20px;
    }

    @media (max-width: 992px) {
        flex-direction: column;
        padding: 0 20px;
        margin-top: 200px;

        &-title {
            max-width: 800px;
            text-align: center;
            margin-right: 0;
        }
    }
}
</style>