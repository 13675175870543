<template>
    <div class="card">
        <!-- <transition-group name='fade' tag='div'> -->
        <div class="card-wrapper fade" v-for="i in [currentIndex]" :key="i">
            <div class="card-slider-section">
                <img
                rel="preload"
                class="card-img"
                :src="require(`../assets/images/${currentSlide.imgName}`)" 
                alt="iPhone"
                />
                <div class="card-dots-section">
                    <div
                    v-for="i in dots" :key="i"
                    @click="changeSlide(i)"
                    class="card-dots" 
                    :style="{ backgroundColor: currentSlide.id === i ? activeColor : backgroundColor  }"
                    >
                    
                    </div>
                </div>
            </div>
            
            <div class="card-description fade">
                <p
                :style="{ color: descriptionColor }"
                >
                    {{ currentSlide.description }}
                </p>
            </div>
        </div>
        <!-- </transition-group> -->
    </div>
</template>

<script>
export default {
    name: 'FeatureCard',
    props: {
        features: {
            type: Array,
            required: true
        },
        dotsColorSchema: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            dots: this.features.length,
            timer: null,
            currentIndex: 0,
            backgroundColor: this.dotsColorSchema.backgroundColor,
            activeColor: this.dotsColorSchema.activeColor,
            descriptionColor: this.dotsColorSchema.descriptionColor
        }
    },
    mounted() {
        this.startSlider()
    },
    computed: {
        currentSlide() {
            return this.features[Math.abs(this.currentIndex) % this.features.length];
        }
    },
    methods: {
        changeSlide(i) {
            this.currentIndex = i - 1
        },
        startSlider() {
            this.timer = setInterval(this.next, 5000);
        },
        next() {
            this.currentIndex += 1
            if (this.currentIndex > this.features.length) { this.currentIndex = 1 }
        }
    },
}
</script>

<style lang="scss" scoped> 
.card {
    display: flex;

    &-wrapper {
        display: flex;
    }

    &-slider-section {
        display: flex;
        flex-direction: column;
    }

    &-img {
        // max-width: 100%;
    }

    &-dots-section {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }

    &-dots {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
        cursor: pointer;
        // background: #D8D8D8;
    }

    &-description {
        align-self: flex-end;
        margin-bottom: 40px;
        width: 336px;
        margin-left: 44px;
    }
    @media (max-width: 992px) {
        justify-content: center;

        &-wrapper {
            flex-direction: column;
            align-items: center;
        }

        &-img {
            margin-top: 20px;
            width: 250px;
            height: auto;
        }

        &-dots-section {
            margin-top: 20px;
        }

        &-description {
            display: flex;
            justify-content: center;
            // align-items: center;
            margin: 0;
        }

        &-description p {
            font-size: 16px;
            height: 94px;
            width: 250px;
            margin-top: 20px;
        }
    }
}  
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}
@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}
</style>