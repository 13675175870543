<template>
    <div class="advantage-card">
        <div class="advantage-card-title">
            {{ title }}
        </div>
        <div
        class="advantage-card-icon"
        :style="{ backgroundImage:  this.image }"
        >
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdvantageCard',
    props: {
        title: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            image: `url(${require(`../assets/icons/${this.icon}`)})`
        }
    },
}
</script>

<style lang="scss" scoped>
.advantage-card {
    display: flex;
    justify-content: space-around;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px -4px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 32px;
    margin-left: 16px;
    max-width: 512px;
    height: 224px;

    &-title {
        max-width: 272px;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #1A233F;
    }

    &-icon {
        align-self: center;
        width: 150px;
        height: 150px;
        background-repeat: no-repeat;
    }

    @media (max-width: 768px) {

        &-icon {
            margin-top: 16px;
            
        }
        
    }
}
</style>