<template>
    <section class="caa-section">
        <div class="caa-section-wrapper">
            <h2 class="caa-section-title">Automatic savings while shopping in Safari</h2>
        </div>

        <feature-card
        :features="features"
        :dotsColorSchema="dotsColor.dark"
        />
    </section>
</template>

<script>
import FeatureCard from '@/components/FeatureCard'
import { CAA_FEATURE_SECTION_SLIDES, SLIDER_DOTS_COLOR } from '@/assets/constants'

export default {
    name: 'CaaFeatureSection',
    components: {
        FeatureCard
    },
    computed: {
        features() {
            return CAA_FEATURE_SECTION_SLIDES
        },
        dotsColor() {
            return SLIDER_DOTS_COLOR
        }
    }
}
</script>

<style lang="scss" scoped>
.caa-section {
    display: flex;
    justify-content: center;
    margin-top: 150px;
    background-color: #2F80ED;
    height: 620px;
    border-radius: 32px;
    padding: 120px 0px;

    &-title {
        max-width: 400px;
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        margin-right: 40px;
        color: #FFFFFF;
    }
    @media (max-width: 1200px) {
        padding: 120px 20px;
    }

    @media (max-width: 992px) {
        flex-direction: column;
        height: 950px;
        padding: 0 20px;

        &-title {
            text-align: center;
            margin-right: 0;
            max-width: 800px;
        }
    }

    
}
</style>