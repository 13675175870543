<template>
    <div class="offers-slider">
        <controls
        @controlClick="prev"
        :showControls="this.showPrevBtn"
        :varient="'prev'"
        />
        <div class="offers-slider-item-wrapper">
            
            <div 
            v-for="item in currentPageItems()"
            :key="item.id"
            class="offers-slider-item"
            :class="animationClass"
            >
            <component 
            :is="cardName"
            :item="item"
            />
            
            </div>
            
        </div>
        
        <controls
        @controlClick="next"
        :varient="'next'"
        :showControls="this.showNextBtn"
        />
    </div>
</template>

<script>
import DealCard from '@/components/DealCard'
import CouponCard from '@/components/CouponCard'
import Controls from '@/components/Controls'


export default {
    name: 'OffersSlider',
    components: {
        DealCard,
        CouponCard,
        Controls
    },
    
    props: {
        cardName: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        itemsPerPage: {
            type: Number,
            required: true
        },
        pageNumber: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            page: this.pageNumber,
            pageItems: this.itemsPerPage,
            animationClass: '.slide-right',
            showNextBtn: true,
            showPrevBtn: true
        }
    },
    created() {
        this.initControls()
        // console.log(window.innerWidth)
    },
    methods: {
        currentPageItems() {
            return this.items.slice((this.page -1) * this.pageItems, this.page * this.pageItems)
        },
        next() {
            this.animationClass = 'slide-right'
            if (this.page < this.items.length / this.pageItems) {
                this.page += 1;
                this.showPrevBtn = true
                // this.animation = true
            }
            if (this.page >= this.items.length / this.pageItems ) {
                this.showNextBtn = false
            }        
        },
        prev() {
            this.animationClass = 'slide-left'
            if (this.page > 1) {
                this.showNextBtn = true 
                this.page -= 1;  
            } 
            this.initControls()
            
        },
        initControls() {
            if (this.page === 1) {
                this.showPrevBtn = false
            }
        }
    },
}
</script>

<style lang="scss">
.offers-slider {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;


    &-item-wrapper {
        display: flex;

        // justify-content: space-ber;
    }

    &-item {
        margin-left: 8px;
        margin-right: 8px;
        

    }
    @media (max-width: 992px) {

        justify-content: space-around;

        &-item {
            margin-left: 8px;
            margin-right: 8px;
        }
    }

    @media (max-width: 767px) {

        // justify-content: space-around;

        &-item {
            margin-left: 4px;
            margin-right: 4px;
        }
    }

    
}

.slide-right {
  animation-name: slide-right;
  animation-duration: .4s;
//   transition: 1s;
}

.slide-left {
  animation-name: slide-left;
  animation-duration: .4s;
//   transition: 1s;
}

@keyframes slide-right {
    from {
        transform: translateX(10%);
        opacity: .5;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
    
//    from {
//     transform: translateX(10%);
//   }
//   
}

@keyframes slide-left {
   from {
    transform: translateX(-10%);
    opacity: .5;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
</style>